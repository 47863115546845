<template>
  <div>
    <div class="w-full" v-if="type === 'empty'">
      <div
        class="border-2 border-solid border-dokBlue-ultra text-dokBlue-ultra py-5 pl-3 pr-1 rounded shadow w-full message-alert flex items-start"
      >
        <img
          src="/svg/undraw_No_data_re_kwbl.svg"
          style="height: 45px"
          class="mr-2"
        />
        <div class="flex flex-col" v-if="message">
          <h3 class="text-dokBlue-ultra">Bonjour !</h3>
          <span class="text-base font-semibold text-blackdok">{{
            message
          }}</span>
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-col items-center justify-center"
      v-if="type === 'empty1'"
    >
      <span
        v-if="message"
        class="border-2 border-solid border-dokBlue-ultra rounded-cu text-base font-semibold text-dokBlue-ultra px-5 py-3"
        >{{ message }}</span
      >
      <img src="/svg/null_data.svg" class="max-w-500" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.message-alert {
  &::before {
    content: " ";
    border: solid transparent 10px;
    border-top-color: #2c75fc;
    position: absolute;
    top: 5px;
    left: -18px;
  }
}
</style>